import { text } from '@fortawesome/fontawesome-svg-core';
import React, {useState} from 'react';
import Modal from "./Modal";

function Portfolio ({currentCategory, isModalOpen, setIsModalOpen, faAngleRight, faAngleLeft, faX}) {
    const category = currentCategory.name;
    const [art] = useState([

        { // 0
          name: 'Pierson\'s Pond',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '40x30',
          price: '$1500'
        },
        { // 1
          name: 'On The Surface',
          category: 'Paintings',
          medium: 'Acrylic on Paper',
          size: '27x19',
          price: '$750'
        },
        { // 2
          name: 'Marsh',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '20x16',
          price: '$600'
        },
        { // 3
          name: 'Ripple',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '36x36',
          price: '$1800'
        },
        { // 4
          name: 'Balinese Wetland',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '20x20',
          price: '$650'
        },
        { // 5
          name: 'Purple Crown Drips',
          category: 'Paintings',
          medium: 'Acrylic on Wood',
          size: '12x12',
          price: '$325'
        },
        { // 6
          name: 'Trinity',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '40x30',
          price: '$1500'
        },
        { // 7
          name: 'Flourish',
          category: 'Paintings',
          medium: 'Acrylic on Canvas',
          size: '24x24',
          price: '$650'
        },
        { // 13
          name: 'Lagoon View',
          category: 'Paintings',
          medium: 'Acrylic on Wood',
          size: '12x12',
          price: '$325'
        },
        { // 9
          name: 'A Time To Blossom',
          category: 'Paintings',
          medium: 'Acrylic on Wood',
          size: '12x12',
          price: '$325'
        },
        { // 10
          name: 'Stacking',
          category: 'Paintings',
          medium: 'Acrylic on Paper',
          size: '7x7',
          price: '$150'
        },
        { // 11
          name: 'Storm\'s Coming',
          category: 'Paintings',
          medium: 'Acrylic on Paper',
          size: '20x24',
          price: '$1400'
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        {
          name: '',
          category: 'Children Playing',
          medium: '',
          size: '',
          price: ''
        },
        { // 0
          name: 'Volcano',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7x7',
          price: '$120'
        },
        { // 1
          name: 'Glow',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$120'
        },
        { // 2
          name: 'Red Landscape',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$120'
        },
        { // 3
          name: 'Heat Wave',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$150'
        },
        { // 4
          name: 'Bali',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '5.5x8',
          price: '$120'
        },
        { // 5
          name: 'A Memory',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$120'
        },
        { // 6
          name: 'Tidepoolin',
          category: 'Mixed Media Collage',
          medium: 'Collage on Wood',
          size: '8x8',
          price: '$150'
        },
        { // 7
          name: 'Morning Dew',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '9.5x7.5',
          price: '$150'
        },
        { // 8
          name: 'Outside Land',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$150'
        },
        { // 9
          name: 'Flow',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$150'
        },
        { // 10
          name: 'Morning Light',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '7.5x7.5',
          price: '$150'
        },
        { // 11 Immersed   Acrylic on Wood  12X12  $325
          name: 'Immersed',
          category: 'Mixed Media Collage',
          medium: 'Collage on Wood',
          size: '12x12',
          price: '$325'
        },
        { // 12
          name: 'On the Table',
          category: 'Mixed Media Collage',
          medium: 'Collage on Wood',
          size: '12x12',
          price: '$325'
        },
        { // 13
          name: 'Tropics',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: 'diptych',
          price: '$350'
        },
        { // 14
          name: 'UP NORTH',
          category: 'Mixed Media Collage',
          medium: 'Collage on Paper',
          size: '16.5x12.5',
          price: '$400'
        },
        { // 0
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 1
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 2
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 3
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 4
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 5
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 6
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 7
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 8
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 9
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 10
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 11
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 12
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 13
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 14
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 15
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        { // 16
          name: '',
          category: 'Select Past Work',
          medium: '',
          size: '',
          price: ''
        },
        // start
        {
          name: 'Aldaron\'s View',
          category: 'Prints & Cards',
          medium: 'Hahnemuhle Photo Rag Cotton paper',
          size: '12x24 $125',
          price: '| 16x32 $225'
        },
        {
          name: 'Low Tide',
          category: 'Prints & Cards',
          medium: 'Hahnemuhle Photo Rag Cotton paper',
          size: '10x14',
          price: '$100'
        },
        {
          name: 'Marsh Sunse3t',
          category: 'Prints & Cards',
          medium: 'Hahnemuhle Photo Rag Cotton paper',
          size: '9.5x22 $125 | 14.5x34 $225',
          price: '| 17x40 $300'
        },
        {
          name: 'Spring',
          category: 'Prints & Cards',
          medium: 'Archival print on Museo cotton paper',
          size: '20x15 $150',
          price: '| 30x22 $225'
        },
        {
          name: 'Ripple',
          category: 'Prints & Cards',
          medium: 'Archival print on Museo cotton paper',
          size: '20x20',
          price: '$150'
        },
        {
          name: 'Bali',
          category: 'Prints & Cards',
          medium: 'Archival print on Museo cotton paper',
          size: '20x13.375',
          price: '$120'
        },
        {
          name: 'Marsh',
          category: 'Prints & Cards',
          medium: 'Archival print on Museo cotton paper',
          size: '15x12',
          price: '$100'
        },
        {
          name: 'Circle of Love',
          category: 'Prints & Cards',
          medium: '-',
          size: '12x12',
          price: '$40'
        },
        {
          name: 'Swimming Pool',
          category: 'Prints & Cards',
          medium: 'Archival print on Museo cotton paper',
          size: '10x10',
          price: '$55'
        },
        {
          name: 'Sue Meg',
          category: 'Prints & Cards',
          medium: '-',
          size: '12x12',
          price: '$40'
        },
        {
          name: 'Our Garden',
          category: 'Prints & Cards',
          medium: '-',
          size: '14x11',
          price: '$40'
        },
        { // 11
          name: 'Storm\'s Coming',
          category: 'Prints & Cards',
          medium: '_',
          size: '20x24',
          price: '$150'
        },
        /* 
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '1',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '2',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '3',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '4',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '5',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '6',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '7',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '8',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '9',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '10',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '11',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '12',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '13',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '14',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '15',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '16',
          size: '',
          price: 'Any 6 for $20'
        },
        {
          name: 'Card',
          category: 'Prints & Cards',
          medium: '17',
          size: '',
          price: 'Any 6 for $20'
        },
        */

  ]);

  const currentArtwork = art.filter((image) => image.category === category);
  const [currentPhoto, setCurrentPhoto] = useState(currentArtwork[0]);

  const toggleModal = (image, i) => {
      setCurrentPhoto({...image, index: i});
      setIsModalOpen(!isModalOpen);
  }

  return (
    <div className='portfolio'>
      {isModalOpen  &&   
          <Modal onClose={toggleModal} 
            currentPhoto={currentPhoto} 
            currentArtwork={currentArtwork}
            faAngleRight = {faAngleRight}
            faAngleLeft = {faAngleLeft}   
            faX = {faX}   
          />}
                      {category === 'Children Playing' && 
            <div className="children-playing-text-container">
                <p>I love to paint movement. These are examples of past commissions and paintings I've done of my own children.</p>
                <br></br>
                <p>If you have a photo you would like painted please contact me.</p>
            </div>}
      <div className= 'portfolio-container'>
          
          { !isModalOpen && 
          <>
              {currentArtwork.map((image, i) => (
                  <div className='portfolio-img-container' key={i} >
                  <img
                      src={require(`../assets/small/${category}/${i}.jpg`)}
                      alt={image.name}
                      onClick={() => toggleModal(image, i)}
                      key={image.name}
                      
                      width={image.name === 'Card' && 150}
                      
                  />
                  <div 
                  className='portfolio-img-info'
                  style={{textAlign: `${image.name === 'Card' && "center"}`}}
                  // style={{textAlign: `${image.name === 'Print' && "center"}`}}
                  >
                      <p>{image.name}</p>
                      <p>{image.medium}</p>
                      <p>{image.size} {image.price}</p>
                  </div>
                  </div>
              ))}
          </>
            }
      </div>
    </div>
  );
}

export default Portfolio;